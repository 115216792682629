import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { safeToIsoString } from '@inspiren-monorepo/util-formatters';
import type { EventReviewTypes } from '@inspiren-monorepo/virtual-care/api-contracts';

import { extractRoomNumberFromRoomId } from '../../../utility/helpers/id';
import { getURLSearchParams } from '../helpers/getURLSearchParams';
import { useEventReviewStore } from '../store/EventReviewStore';

interface UpdateUrlParams {
  orgs: EventReviewTypes.Organization[];
  units?: EventReviewTypes.Unit[];
  filteredRooms: EventReviewTypes.Room[];
  orgsLoading: boolean;
  unitsLoading: boolean;
  roomsLoading: boolean;
}

const isRoomFromUrl = (
  room: EventReviewTypes.Room | null,
  urlRoom: string,
): boolean =>
  !!room &&
  (extractRoomNumberFromRoomId(room.domainId) === urlRoom ||
    room.displayName === urlRoom);

export const useUpdateUrlParams = ({
  orgs,
  units,
  filteredRooms,
  orgsLoading,
  unitsLoading,
  roomsLoading,
}: UpdateUrlParams) => {
  const {
    selectedOrg,
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    setSelectedOrg,
    setSelectedUnit,
    setSelectedRoom,
    setStartDate,
    setEndDate,
  } = useEventReviewStore();

  useEffect(() => {
    const { urlOrg, urlUnit, urlRoom, urlStart, urlEnd } = getURLSearchParams();

    if (!urlOrg || !urlUnit || !urlRoom || !urlStart || !urlEnd) {
      return;
    }

    if (
      selectedOrg?.id === urlOrg &&
      `${selectedUnit?.domainId}` === urlUnit &&
      isRoomFromUrl(selectedRoom, urlRoom) &&
      (safeToIsoString(startDate) || null) === (urlStart || null) &&
      (safeToIsoString(endDate) || null) === (urlEnd || null)
    ) {
      return;
    }

    if (orgsLoading || unitsLoading) {
      return;
    }

    const org = orgs.find((o) => o.id === urlOrg);

    if (!org) {
      toast.error('Invalid organization in url parameters');
      return;
    }

    const unit = units?.find((u) => `${u.domainId}` === urlUnit);

    if (!unit) {
      toast.error('Invalid unit in url parameters');
      return;
    }

    setSelectedOrg(org);
    setSelectedUnit(unit);

    const room = filteredRooms.find((r) => isRoomFromUrl(r, urlRoom));

    if (!room) {
      if (!roomsLoading && filteredRooms.length) {
        toast.error('Invalid room in url parameters');
      }

      return;
    }

    const start = new Date(urlStart);
    const now = new Date();

    if (start > now) {
      toast.error('Invalid start date in url parameters');
      return;
    }

    const end = new Date(urlEnd);

    if (end < start) {
      toast.error('Invalid end date in url parameters');
      return;
    }

    setSelectedRoom(room);
    setStartDate(start);
    setEndDate(end);
  }, [orgs, units, filteredRooms, orgsLoading, unitsLoading, roomsLoading]);
};
